import { combineReducers } from 'redux';
//import reducers from "../reducers";
import { configureStore } from '@reduxjs/toolkit';

import { persistReducer } from 'redux-persist';
import thunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage';

import ThemeOptions from '../reducers/ThemeOptions';
import OrderOverviewReducer from '../reducers/OrderOverviewSlice';
import BuilderOverviewReducer from '../reducers/BuilderOverviewSlice';
import TechnicianOverviewReducer from '../reducers/TechnicianOverviewSlice';
import TechnicianStorageReducer from '../reducers/TechnicianStorageSlice';
import MessagesReducer from '../reducers/MessagesSlice';
import FogUnitReducer from '../reducers/FogUnitSlice';
import EvaluationReducer from '../reducers/EvaluationSlice';

const reducers = combineReducers({
	ThemeOptions,
	orderOverview: OrderOverviewReducer,
	builderOverview: BuilderOverviewReducer,
	technicianOverview: TechnicianOverviewReducer,
	technicianStorage: TechnicianStorageReducer,
	messagesOverview: MessagesReducer,
	fogOverview: FogUnitReducer,
	evaluation: EvaluationReducer,
});

const persistConfig = {
	key: 'root',
	storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
	reducer: persistedReducer,
	middleware: [thunk],
});

export default store;

/*


export const store = configureStore({
  reducer: {
    ...reducers
  }
});
*/
