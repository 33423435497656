export const AdminDashboardMenu = [
	{
		icon: 'pe-7s-graph2',
		label: 'Dashboard',
		to: '#/admin/dashboard',
	},
	{
		icon: 'lnr-briefcase',
		label: 'Aufträge',
		to: '#/admin/auftraege',
	},
	{
		icon: 'pe-7s-user',
		label: 'IBS-Techniker',
		to: '#/admin/techniker',
	},
	{
		icon: 'lnr-users',
		label: 'Errichterübersicht',
		to: '#/admin/errichter',
	},
	{
		icon: 'pe-7s-display1',
		label: 'Auswertungen',
		to: '#/admin/auswertungen',
	},
	{
		icon: 'pe-7s-chat',
		label: 'Benachrichtigungen',
		to: '#/admin/benachrichtigungen',
	},
];

export const TechnicianDashboardMenu = [
	{
		icon: 'pe-7s-graph2',
		label: 'Dashboard',
		to: '#/techniker/dashboard',
	},
	{
		icon: 'lnr-construction',
		label: 'Aufträge',
		to: '#/techniker/auftraege',
	},
	{
		icon: 'lnr-briefcase',
		label: 'Lager',
		to: '#/techniker/lager',
	},
];

export const ExternalTechnicianDashboardMenu = [
	{
		icon: 'pe-7s-graph2',
		label: 'Dashboard',
		to: '#/techniker/dashboard',
	},
	{
		icon: 'lnr-construction',
		label: 'Aufträge',
		to: '#/techniker/auftraege',
	},
];

export const BuilderDashboardMenu = [
	{
		icon: 'pe-7s-graph2',
		label: 'Dashboard',
		to: '#/errichter/dashboard',
	},
	{
		icon: 'lnr-construction',
		label: 'Aufträge',
		to: '#/errichter/auftraege',
	},
	{
		icon: 'pe-7s-user',
		label: 'Techniker',
		to: '#/errichter/techniker',
	},
];

export const LogoutMenu = [
	{
		icon: 'lnr-exit',
		label: 'Logout',
		to: '#/logout',
	},
];
