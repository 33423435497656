import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API_ADMIN_ORDER_TYPES, API_ADMIN_UNCLOSED_ORDERS } from '../App/Components/API/endpoints';

const initialState = {
	total: 0,
	filter: { arten: [], typen: [], techn: [], tage: { value: '7', label: '7-Tage' } },
	page: 1,
	perPage: 10,
	entries: [],
	chartData: [],
	status: 'idle', // idle | loading | succeeded | failed
	error: null,
	sortBy: 'name',
	sortOrder: 'ASC',
	orderTypes: { arten: [], typen: [], techn: [] },
};

export const fetchUnclosedOrdersEvaluation = createAsyncThunk('orders/fetchAdminBuilders', async (arg, { getState }) => {
	const axios = arg.axios;
	const params = ``;
	const state = getState();
	const filter = state.evaluation.filter;
	const data = {
		arten: filter.arten.map((f) => f.id),
		typen: filter.typen.map((f) => f.id),
		techn: filter.techn.map((f) => f.id),
		tage: filter.tage.value,
	};
	const response = await axios.post(API_ADMIN_UNCLOSED_ORDERS + params, data);
	return response.data;
});

export const fetchAvailableOrderTypes = createAsyncThunk('orders/fetchOrderTypes', async (arg) => {
	const axios = arg.axios;
	const response = await axios.get(API_ADMIN_ORDER_TYPES);
	return response.data;
});

export const EvaluationSlice = createSlice({
	name: 'evaluation',
	initialState,
	reducers: {
		resetFilter: {
			reducer(state, action) {
				state.filter = initialState.filter;
				state.page = initialState.page;
				state.perPage = initialState.perPage;
				state.sortBy = initialState.sortBy;
				state.sortOrder = initialState.sortOrder;
			},
			prepare() {
				return {};
			},
		},
		setPerPage: {
			reducer(state, action) {
				state.perPage = action.payload.perPage;
			},
			prepare(perPage) {
				return {
					payload: {
						perPage,
					},
				};
			},
		},
		setTotal: {
			reducer(state, action) {
				state.total = action.payload.total;
			},
			prepare(total) {
				return {
					payload: {
						total,
					},
				};
			},
		},
		setPage: {
			reducer(state, action) {
				state.page = action.payload.page;
			},
			prepare(page) {
				return { payload: { page } };
			},
		},
		setFilter: {
			reducer(state, action) {
				state.filter = action.payload.filter;
			},
			prepare(filter) {
				return {
					payload: {
						filter,
					},
				};
			},
		},
		setSortByAndSortOrder: {
			reducer(state, action) {
				state.sortBy = action.payload.sortBy;
				state.sortOrder = action.payload.sortOrder;
			},
			prepare(sortBy, sortOrder) {
				return {
					payload: {
						sortBy,
						sortOrder,
					},
				};
			},
		},
	},
	extraReducers(builder) {
		builder
			.addCase(fetchUnclosedOrdersEvaluation.pending, (state, action) => {
				state.status = 'loading';
			})
			.addCase(fetchUnclosedOrdersEvaluation.fulfilled, (state, action) => {
				state.status = 'succeeded';
				state.entries = action.payload.data;
				state.total = action.payload.total;
				state.chartData = action.payload.chartData;
			})
			.addCase(fetchUnclosedOrdersEvaluation.rejected, (state, action) => {
				state.status = 'failed';
				state.error = action.error.message;
			})
			.addCase(fetchAvailableOrderTypes.pending, (state, action) => {
				state.status = 'loading';
			})
			.addCase(fetchAvailableOrderTypes.fulfilled, (state, action) => {
				state.status = 'succeeded';
				state.orderTypes = action.payload;
			})
			.addCase(fetchAvailableOrderTypes.rejected, (state, action) => {
				state.status = 'failed';
				state.error = action.error.message;
			});
	},
});

export const { setEntries, setPerPage, setPage, setTotal, setFilter, setSortByAndSortOrder, resetFilter } = EvaluationSlice.actions;

export default EvaluationSlice.reducer;

export const getPage = (state) => state.evaluation.page;
export const getPerPage = (state) => state.evaluation.perPage;
export const getTotal = (state) => state.evaluation.total;
export const getEntries = (state) => state.evaluation.entries;
export const getChartData = (state) => state.evaluation.chartData;
export const getStatus = (state) => state.evaluation.status;
export const getError = (state) => state.evaluation.error;
export const getSortColumn = (state) => state.evaluation.sortBy;
export const getSortOrder = (state) => state.evaluation.sortOrder;
export const getFilter = (state) => state.evaluation.filter;
export const getOrderTypes = (state) => state.evaluation.orderTypes;
