//export const BASE_URL = 'https://217.70.163.253:38703'; // test.system ip with port

export const BASE_URL = 'https://ibs-auftragsportal.de';

export const API_LOGIN_CHECK_ROUTE = BASE_URL + '/api/login_check';
export const API_REGISTRATION_INFORMATION_ROUTE = BASE_URL + '/api/registration/information';
export const API_REGISTRATION_ROUTE = BASE_URL + '/api/benutzer/registrieren';

export const API_ADMIN_ORDER_URL = BASE_URL + '/api/admin/auftraege';
export const API_TECHNICIAN_ORDERS_URL = BASE_URL + '/api/admin/techniker/auftraege';
export const API_TECHNICIAN_ORDER_URL = BASE_URL + '/api/techniker/auftraege';
export const API_BUILDER_ORDER_URL = BASE_URL + '/api/errichter/auftraege';
export const API_ORDERDETAIL_URL = BASE_URL + '/api/auftrag/details';
export const API_ACTION_LOG_URL = BASE_URL + '/api/auftrag/logs';
export const API_TECHNICIAN_FOR_ORDER = BASE_URL + '/api/auftrag/techniker';
export const API_SAVE_ORDER_POSITION = BASE_URL + '/api/position/anlegen';
export const API_LOAD_ORDER_POSITION = BASE_URL + '/api/position/daten';
export const API_IMAGES_ROUTE = BASE_URL + '/api/auftrag/foto';
export const API_CANCEL_POSITION = BASE_URL + '/api/auftrag/position/__ID__/stornieren';
export const API_SIGN_ORDER = BASE_URL + '/api/auftrag/unterzeichnen';
export const API_SUPPLEMENT_RETURN_TRIP = BASE_URL + '/api/auftrag/position/rueckfahrt/protokollieren';
export const API_START_WORK_ON_ORDER = BASE_URL + '/api/auftragsbeginn-protokollieren/';
export const API_RESET_ORDER = BASE_URL + '/api/auftrag/zuruecksetzen/';
export const API_RE_OPEN_ORDER = BASE_URL + '/api/auftrag/oeffnen/';

export const API_DOWNLOAD_ORDER_PDF = BASE_URL + '/api/download/auftragszettel/';
export const API_DOWNLOAD_RETURNJOURNEYS_PDF = BASE_URL + '/api/download/rueckfahrt/';
export const API_DOWNLOAD_DOCUMENT = BASE_URL + '/api/download/dokument/';
export const API_DOWNLOAD_PARTIAL_ORDER_PDF = BASE_URL + '/api/download/auszug/dokument';

export const API_REFRESH_ROUTE = BASE_URL + '/api/token/refresh';

export const API_INVALIDATE_ROUTE = BASE_URL + '/api/token/invalidate';

export const API_DOWNLOAD_ORDER_DOCUMENT = BASE_URL + '/api/download/document/';
export const API_DOWNLOAD_ALL_DOCUMENTS_FOR_ORDER = BASE_URL + '/api/download/unterlagen/';

export const API_DOCUMENT_ROUTE = BASE_URL + '/api/auftrag/dokument';
export const API_ORDER_DOCUMENT_ROUTE = BASE_URL + '/api/auftrag/auftragszettel';

export const API_TECHNICIAN_ASSIGNMENT = BASE_URL + '/api/auftrag/techniker/zuweisung';
export const API_ORDER_ASSIGNMENT = BASE_URL + '/api/techniker/auftrag/zuweisung';
export const API_SAVE_ASSIGNMENT = BASE_URL + '/api/zuweisung/speichern';
export const API_BUILDER_ASSIGNMENT = BASE_URL + '/api/errichter/zuweisung';
export const API_SAVE_BUILDER_ASSIGNMENT = BASE_URL + '/api/errichter-zuweisung/speichern';

export const API_SEND_ORDER_DOCUMENTS_ROUTE = BASE_URL + '/api/auftrag/versenden';
export const API_SEND_TECHNICIAN_ASSIGNMENTS_CHANGED = BASE_URL + '/api/mail/auftraege/geaendert';
export const API_SEND_ORDER_ASSIGNMENTS_CHANGED = BASE_URL + '/api/mail/techniker/geaendert';

export const API_TECHNICIAN_STORAGE = BASE_URL + '/api/techniker/lager';
export const API_TECHNICIAN_STORAGE_ITEM_DETAILS = BASE_URL + '/api/techniker/lager/artikel/details';
export const API_TECHNICIAN_STORAGE_ITEM_BOOKING = BASE_URL + '/api/techniker/lager/artikel/buchungsliste';
export const API_TECHNICIAN_STORE_STORAGE_ITEM_BOOKINGS = BASE_URL + '/api/techniker/lager/artikel/buchungen/aus/liste';
export const API_TECHNICIAN_BOOKED_STORAGE_ITEM_BOOKINGS = BASE_URL + '/api/techniker/lager/artikel/buchungen';
export const API_TECHNICIAN_BOOK_STORAGE_ITEMS_ON_ORDER = BASE_URL + '/api/techniker/lager/artikelliste/buchen';
export const API_TECHNICIAN_STORAGE_CREATE_TRANSFER_RECEIPT = BASE_URL + '/api/techniker/lager/umbuchungsbeleg/erstellen';
export const API_TECHNICIAN_STORAGE_CREATE_TRANSFER_RECEIPT_AND_SEND_VIA_EMAIL = BASE_URL + '/api/techniker/lager/umbuchungsbeleg/email';
export const API_TECHNICIAN_RETURN_STORAGE_BOOKING_INFORMATIONS = BASE_URL + '/api/techniker/lager/umbuchungsbeleg/informationen';

export const API_TECHNICIAN_DASHBOARD_STATISTICS = BASE_URL + '/api/statistik/techniker/dashboard';
export const API_ADMIN_DASHBOARD_STATISTICS = BASE_URL + '/api/statistik/admin/dashboard';
export const API_ADMIN_DASHBOARD_TEST = BASE_URL + '/api/statistik/admin/begonnene/auftraege';
export const API_BUILDER_DASHBOARD_STATISTICS = BASE_URL + '/api/statistik/errichter/dashboard';
export const API_BUILDER_TECHNICIAN_ASSIGNMENT_DASHBOARD_STATISTICS = BASE_URL + '/api/statistik/errichter/auftragszuweisung/dashboard';

export const API_FETCH_USER_INFORMATION = BASE_URL + '/api/benutzer/informationen';
export const API_UPDATE_USER_PASSWORD = BASE_URL + '/api/benutzer/passwort/aktualisieren';

export const API_ADMIN_BUILDER_URL = BASE_URL + '/api/admin/errichter';

export const API_ADMIN_TECHNICIANS_URL = BASE_URL + '/api/admin/techniker';
export const API_BUILDER_TECHNICIANS_URL = BASE_URL + '/api/errichter/techniker';
export const API_SAVE_TECHNICIAN_URL = BASE_URL + '/api/errichter/speichern/techniker';
export const API_CHECK_USERNAME_URL = BASE_URL + '/api/benutzername/pruefen';
export const API_LOAD_TECHNICIAN_URL = BASE_URL + '/api/errichter/laden/techniker';
export const API_LOAD_BUILDERS_URL = BASE_URL + '/api/errichter/hierarchie/laden';
export const API_DELETE_TECHNICIAN_URL = BASE_URL + '/api/errichter/loeschen/techniker';
export const API_SWITCH_TECHNICIAN_ACTIVATION_URL = BASE_URL + '/api/errichter/aktivieren/techniker';
export const API_CREATE_RETURN_TRIP_DOCUMENT_URL = BASE_URL + '/api/auftrag/rueckfahrt-dokument-erzeugen';
export const API_FETCH_WORKTIMES_FOR_TECHNICIAN_ON_DATE = BASE_URL + '/api/techniker/zeiten';

// API für die Arbeiter
export const API_FILTERED_STORAGE_ITEMS_FOR_ORDER = BASE_URL + '/api/lager/auftragsartikel';

export const API_FETCH_ORDER_ITEMS = BASE_URL + '/api/auftrag/artikel/gebucht/';
export const API_TOGGLE_INVOICED_STATE = BASE_URL + '/api/auftrag/berechnet/';

// An-/Abreise API Endpoints
export const API_SAVE_DEPARTMENT = BASE_URL + '/api/stundenbuchung/erzeugen/';
export const API_LOAD_DEPARTMENTS = BASE_URL + '/api/stundenbuchung/liste/';
export const API_CANCEL_DEPARTMENTS = BASE_URL + '/api/stundenbuchung/stornieren/';
export const API_CREATE_RETURN_JOURNEY = BASE_URL + '/api/stundenbuchung/rueckreise/erzeugen';

export const API_SAVE_NEW_MILEAGE_FOR_POSITION = BASE_URL + '/api/auftrag/km/fix';

export const API_SAVE_SETTING_SHOW_CLOSED_ORDERS_IN_DAYS = BASE_URL + '/api/benutzer/auftragsAnzeigeInTagen';

export const API_FETCH_CURRENT_MESSAGES = BASE_URL + '/api/json/aktuelle/nachrichten';
export const API_CREATE_MESSAGE = BASE_URL + '/api/json/benachrichtigung/anlegen';
export const API_FETCH_USER_MESSAGES = BASE_URL + '/api/json/aktuelle/benutzer/nachrichten';
export const API_HIDE_USER_MESSAGE = BASE_URL + '/api/json/benutzer/nachricht/ausblenden';
export const API_USER_MESSAGE_SHOWN = BASE_URL + '/api/json/benutzer/nachricht/angezeigt';
export const API_DELETE_MESSAGE = BASE_URL + '/api/json/benachrichtigung/loeschen';
export const API_FETCH_MESSAGES_FOR_THREE_MONTHS = BASE_URL + '/api/json/drei-monate/nachrichten';
export const API_DELETE_MSSAGE_FOR_USER = BASE_URL + '/api/json/nachricht/loeschen';

export const API_BEGIN_FOG_UNIT_CHECK = BASE_URL + '/api/auftrag/nebelzettel/starten';
export const API_FINISH_FOG_UNIT_CHECK = BASE_URL + '/api/auftrag/nebelzettel/abschliessen';
export const API_FOG_UNIT_DATA_FOR_ORDER = BASE_URL + '/api/auftrag/nebelzettel';
export const API_FOG_UNIT_EDIT = BASE_URL + '/api/auftrag/nebelgeraet/bearbeiten';
export const API_FOG_UNIT_CHECKS = BASE_URL + '/api/auftrag/nebelgeraet/check';
export const API_FOG_UNIT_CHECKS_RESET = BASE_URL + '/api/auftrag/nebelgeraetecheck/zuruecksetzen';
export const API_FOG_UNIT_COMMENT_UPDATE = BASE_URL + '/api/auftrag/nebelgeraet/kommentar/bearbeiten';

export const API_IBS_TECHNICIAN_LIST = BASE_URL + '/api/techniker/liste/ibs';
export const API_CREATE_TECHNICIAN_STORAGE_BOOKING = BASE_URL + '/api/techniker/umbuchungswunsch/anlegen';
export const API_IBS_TECHNICIAN_STORAGE_TRADES = BASE_URL + '/api/techniker/umbuchungswuensche';
export const API_CANCEL_STORAGE_BOOKING = BASE_URL + '/api/techniker/umbuchungswunsch/stornieren';
export const API_CONFIRM_STORAGE_BOOKING = BASE_URL + '/api/techniker/umbuchungswunsch/bestaetigen';

export const API_ADMIN_UNCLOSED_ORDERS = BASE_URL + '/api/admin/auswertung/nicht-abgeschlossen/';
export const API_ADMIN_ORDER_TYPES = BASE_URL + '/api/admin/auswertung/auftragstypen/';
