import React, { Fragment, useState } from 'react';
import { Input, DropdownToggle, DropdownMenu, UncontrolledButtonDropdown, Button, Nav, NavItem, NavLink } from 'reactstrap';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Buffer } from 'buffer';
import city3 from '../../../assets/utils/images/dropdown-header/city3.jpg';
import avatar1 from '../../../assets/utils/images/avatars/profile64.png';
import useAuth from '../../../App/Components/Hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import ChangePasswordForm from '../../../App/Components/Common/ChangePasswordForm';
import { saveSettingShowClosedOrdersInDays } from '../../../reducers/OrderOverviewSlice';
import useAxiosPrivate from '../../../App/Components/Hooks/useAxiosPrivate';
import { useDispatch } from 'react-redux';
import { toast, Bounce } from 'react-toastify';
import MessageDropdownComponent from '../../../App/Components/Common/MessageDropdownComponent';
import cx from 'classnames';

const UserBox = (props) => {
	const getUserRoleName = (role) => {
		if (role === '101') {
			return 'Administrator (ibs)';
		} else if (role === '201') {
			return 'Techniker (ibs)';
		} else if (role === '202') {
			return 'Techniker';
		} else if (role === '301') {
			return 'Errichter (ibs)';
		} else if (role === '302') {
			return 'Errichter';
		}
		return '';
	};

	const dispatch = useDispatch();
	const axios = useAxiosPrivate();
	const [showDialog, setShowDialog] = useState(false);
	const auth = useAuth();
	const accessToken = !auth?.auth?.accessToken ? null : auth?.auth?.accessToken;
	const split = !!accessToken ? accessToken.split('.')[1] : '';
	const buffer = !!accessToken ? Buffer.from(split, 'base64') : null;
	const parsed = !!accessToken ? JSON.parse(buffer) : null;

	const username = !!parsed ? `${!!parsed.vorname ? parsed.vorname : ''} ${!!parsed.nachname ? parsed.nachname : ''}`.trim() : '';
	const userRole = !!parsed ? getUserRoleName(parsed.roles) : '';
	const navigate = useNavigate();
	const [formularTyp, setFormularTyp] = useState(!!localStorage.getItem('of') ? localStorage.getItem('of') : '0');
	const [showAccumulatedHours, setShowAccumulatedHours] = useState(!!localStorage.getItem('ah') ? Number.parseInt(localStorage.getItem('ah')) : 0);

	const handleShowAccumulatedHours = (value) => {
		localStorage.setItem('ah', value);
		setShowAccumulatedHours(value);
	};

	const handleClick = (value) => {
		localStorage.setItem('of', value);
		setFormularTyp(value);
	};

	const handleOrdersInDaysChange = async (value) => {
		dispatch(saveSettingShowClosedOrdersInDays({ axios, data: { scoid: value } })).then((response) => {
			if (response?.payload?.status === 200) {
				window.location.reload();
			} else {
				toast('Fehler beim Speichern der Benutzereinstellungen', {
					transition: Bounce,
					closeButton: true,
					autoClose: 5000,
					position: 'top-right',
					type: 'error',
				});
			}
		});
	};

	const { closedOrdersInDays } = useAuth();

	const logout = () => {
		navigate('/logout');
	};

	return (
		<Fragment>
			<div className='header-btn-lg pe-0'>
				<div className='widget-content p-0'>
					<div className='widget-content-wrapper'>
						<div className='widget-content-left'>
							<MessageDropdownComponent user={username} />
						</div>
						<div className='widget-content-left'>
							<UncontrolledButtonDropdown>
								<DropdownToggle color='link' className='p-0'>
									<img width={42} className='rounded-circle' src={avatar1} alt='' />
									<FontAwesomeIcon className='ms-2 opacity-8' icon={faAngleDown} />
								</DropdownToggle>
								<DropdownMenu end className='rm-pointers dropdown-menu-lg'>
									<div className='dropdown-menu-header mb-0'>
										<div className='dropdown-menu-header-inner bg-info'>
											<div
												className='menu-header-image opacity-2'
												style={{
													backgroundImage: 'url(' + city3 + ')',
												}}
											/>
											<div className='menu-header-content text-start'>
												<div className='widget-content p-0'>
													<div className='widget-content-wrapper'>
														<div className='widget-content-left me-3'>
															<img width={42} className='rounded-circle' src={avatar1} alt='' />
														</div>
														<div className='widget-content-left'>
															<div className='widget-heading'>{username}</div>
															<div className='widget-subheading opacity-8'>{userRole}</div>
														</div>
														<div className='widget-content-right me-2'>
															<Button className='btn-pill btn-shadow btn-shine' color='focus' onClick={() => logout()}>
																Logout
															</Button>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									{!showDialog && (
										<div className='scroll-area-xs' style={{ height: '320px' }}>
											<PerfectScrollbar>
												<Nav vertical>
													<NavItem className='nav-item-header'>Unterschriftsformular</NavItem>
													<NavItem>
														<NavLink>
															<Input
																type='select'
																name='formType'
																onChange={(e) => {
																	handleClick(e.target.value);
																}}
																value={formularTyp}
															>
																<option value='0'>Positionsbezogen anzeigen</option>
																<option value='1'>Als Auftragszettel anzeigen</option>
															</Input>
														</NavLink>
													</NavItem>
													<NavItem className='nav-item-header'>Geschlossene Aufträge</NavItem>
													<NavItem>
														<NavLink>
															<Input
																type='select'
																name='ordersInDays'
																onChange={(e) => {
																	handleOrdersInDaysChange(e.target.value);
																}}
																defaultValue={closedOrdersInDays}
															>
																<option value={14}>14-Tage lang sichtbar</option>
																<option value={21}>21-Tage lang sichtbar</option>
																<option value={28}>28-Tage lang sichtbar</option>
															</Input>
														</NavLink>
													</NavItem>
													<NavItem className='nav-item-header'>Kummulierte Stunden in Auftrag anzeigen</NavItem>
													{/*
													<NavItem>
														<NavLink>
															<div
																className='switch has-switch'
																data-on-label='ON'
																data-off-label='OFF'
																onClick={() => handleShowAccumulatedHours(!!showAccumulatedHours ? '0' : '1')}
															>
																<div
																	className={cx('switch-animate', {
																		'switch-on': showAccumulatedHours,
																		'switch-off': !showAccumulatedHours,
																	})}
																>
																	<input type='checkbox' />
																	<span className='switch-left bg-success'>Ja</span>
																	<label>&nbsp;</label>
																	<span className='switch-right bg-success'>Nein</span>
																</div>
															</div>
														</NavLink>
													</NavItem>
													*/}
													<NavItem className='nav-item-header'>Profil</NavItem>
													<NavItem>
														<NavLink onClick={(e) => setShowDialog(true)}>Profil bearbeiten</NavLink>
													</NavItem>
												</Nav>
											</PerfectScrollbar>
										</div>
									)}
									{showDialog && (
										<ChangePasswordForm
											close={() => {
												setShowDialog(false);
											}}
										/>
									)}
								</DropdownMenu>
							</UncontrolledButtonDropdown>
						</div>
						<div className='widget-content-left  ms-3 header-user-info'>
							<div className='widget-heading'>{username}</div>
							<div className='widget-subheading'>{userRole}</div>
						</div>
						{/* <div className='widget-content-right header-user-info ms-3'>
							<Button className='btn-shadow p-1' size='sm' onClick={notify2} color='info' id='Tooltip-1'>
								<IoIosCalendar color='#ffffff' fontSize='20px' />
							</Button>
							<UncontrolledTooltip placement='bottom' target={'Tooltip-1'}>
								Click for Toastify Notifications!
							</UncontrolledTooltip>
						</div> */}
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default UserBox;
