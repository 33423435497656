import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
	API_ADMIN_TECHNICIANS_URL,
	API_BUILDER_TECHNICIANS_URL,
	API_CHECK_USERNAME_URL,
	API_DELETE_TECHNICIAN_URL,
	API_FETCH_WORKTIMES_FOR_TECHNICIAN_ON_DATE,
	API_LOAD_BUILDERS_URL,
	API_LOAD_TECHNICIAN_URL,
	API_SAVE_TECHNICIAN_URL,
	API_SWITCH_TECHNICIAN_ACTIVATION_URL,
	API_IBS_TECHNICIAN_LIST,
	API_CREATE_TECHNICIAN_STORAGE_BOOKING,
	API_IBS_TECHNICIAN_STORAGE_TRADES,
	API_CANCEL_STORAGE_BOOKING,
	API_CONFIRM_STORAGE_BOOKING,
} from '../App/Components/API/endpoints';

const initialState = {
	total: 0,
	selectedTechnician: null,
	filter: { type: 'all', search: '' },
	page: 1,
	perPage: 10,
	entries: [],
	status: 'idle', // idle | loading | succeeded | failed
	error: null,
	sortBy: 'name',
	sortOrder: 'ASC',
};

export const createStorageBookingForTechnician = createAsyncThunk('technician/createStorageBookingForTechnician', async (arg) => {
	const axios = arg.axios;
	const response = await axios.post(API_CREATE_TECHNICIAN_STORAGE_BOOKING, arg.data);
	return response.data;
});

export const cancelStorageBooking = createAsyncThunk('technician/cancelStorageBooking', async (arg) => {
	const axios = arg.axios;
	const response = await axios.post(API_CANCEL_STORAGE_BOOKING, arg.data);
	return response.data;
});

export const confirmStorageBooking = createAsyncThunk('technician/confirmStorageBooking', async (arg) => {
	const axios = arg.axios;
	const response = await axios.post(API_CONFIRM_STORAGE_BOOKING, arg.data);
	return response.data;
});

export const fetchIbsTechnicians = createAsyncThunk('technician/fetchIbsTechnicians', async (arg) => {
	const axios = arg.axios;
	const response = await axios.get(API_IBS_TECHNICIAN_LIST);
	return response.data;
});

export const fetchStorageTradeBookings = createAsyncThunk('technician/fetchStorageTradeBookings', async (arg) => {
	const axios = arg.axios;
	const data = { ic: arg.ic, page: arg.page, perPage: arg.perPage, filter: arg.filter, sortBy: arg.sortBy, sortOrder: arg.sortOrder };
	const response = await axios.post(API_IBS_TECHNICIAN_STORAGE_TRADES, data);
	return response.data;
});

export const fetchAdminTechnicians = createAsyncThunk('technician/fetchAdminTechnicians', async (arg) => {
	const axios = arg.axios;
	const params = `?page=${arg.page}&perPage=${arg.perPage}&sortBy=${arg.sortBy}&sortOrder=${arg.sortOrder}&search=${arg.filter.search}`;
	const response = await axios.get(API_ADMIN_TECHNICIANS_URL + params);
	return response.data;
});

export const fetchWorkTimesForTechnicianOnDate = createAsyncThunk('technician/fetchWorkTimesForTechnicianOnDate', async (arg) => {
	const axios = arg.axios;
	const response = await axios.post(API_FETCH_WORKTIMES_FOR_TECHNICIAN_ON_DATE, arg.data);
	return response.data;
});

export const fetchBuilderTechnicians = createAsyncThunk('technician/fetchBuilderTechnicians', async (arg) => {
	const axios = arg.axios;
	const params = `?page=${arg.page}&perPage=${arg.perPage}&sortBy=${arg.sortBy}&sortOrder=${arg.sortOrder}&search=${arg.filter.search}`;
	const response = await axios.get(API_BUILDER_TECHNICIANS_URL + params);
	return response.data;
});

export const checkUsername = createAsyncThunk('technician/checkUsername', async (arg) => {
	const axios = arg.axios;
	const response = await axios.post(API_CHECK_USERNAME_URL, { benutzername: arg.username, techniker: arg.technicianID });
	return response.data;
});

export const saveTechnician = createAsyncThunk('technician/saveTechnician', async (arg) => {
	const axios = arg.axios;
	const response = await axios.post(API_SAVE_TECHNICIAN_URL + '/' + arg.technician, arg.form, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	});
	return response.data;
});

export const loadTechnician = createAsyncThunk('technician/loadTechnician', async (arg) => {
	const axios = arg.axios;
	const response = await axios.get(API_LOAD_TECHNICIAN_URL + '/' + arg.technician);
	return response.data;
});

export const loadBuilders = createAsyncThunk('technician/loadBuilder', async (arg) => {
	const axios = arg.axios;
	const response = await axios.get(API_LOAD_BUILDERS_URL);
	return response.data;
});

export const switchTechnicianAccountActivation = createAsyncThunk('technician/switchTechnicianAccountActivation', async (arg) => {
	const axios = arg.axios;
	const response = await axios.get(API_SWITCH_TECHNICIAN_ACTIVATION_URL + '/' + arg.technician);
	return response.data;
});

export const deleteTechnician = createAsyncThunk('technician/deleteTechnician', async (arg) => {
	const axios = arg.axios;
	const response = await axios.get(API_DELETE_TECHNICIAN_URL + '/' + arg.technician);
	return response.data;
});

export const TechnicianOverviewSlice = createSlice({
	name: 'technicians',
	initialState,
	reducers: {
		resetTechnicianFilter: {
			reducer(state, action) {
				state.filter = { type: 'all', search: '' };
			},
			prepare() {
				return {};
			},
		},
		setSelectedTechnician: {
			reducer(state, action) {
				state.selectedTechnician = action.payload.selectedTechnician;
			},
			prepare(selectedTechnician) {
				return {
					payload: {
						selectedTechnician: selectedTechnician,
					},
				};
			},
		},
		setEntries: {
			reducer(state, action) {
				state.entries = action.payload.entries;
			},
			prepare(entries) {
				return {
					payload: {
						entries: entries,
					},
				};
			},
		},
		setPerPage: {
			reducer(state, action) {
				state.perPage = action.payload.perPage;
			},
			prepare(perPage) {
				return {
					payload: {
						perPage,
					},
				};
			},
		},
		setTotal: {
			reducer(state, action) {
				state.total = action.payload.total;
			},
			prepare(total) {
				return {
					payload: {
						total,
					},
				};
			},
		},
		setPage: {
			reducer(state, action) {
				state.page = action.payload.page;
			},
			prepare(page) {
				return {
					payload: {
						page,
					},
				};
			},
		},
		setFilter: {
			reducer(state, action) {
				state.filter = { type: action.payload.type, search: action.payload.search };
			},
			prepare(type, search) {
				return {
					payload: {
						type,
						search,
					},
				};
			},
		},
		setSortByAndSortOrder: {
			reducer(state, action) {
				state.sortBy = action.payload.sortBy;
				state.sortOrder = action.payload.sortOrder;
			},
			prepare(sortBy, sortOrder) {
				return {
					payload: {
						sortBy,
						sortOrder,
					},
				};
			},
		},
	},
	extraReducers(builder) {
		builder
			.addCase(fetchAdminTechnicians.pending, (state, action) => {
				state.status = 'loading';
			})
			.addCase(fetchAdminTechnicians.fulfilled, (state, action) => {
				state.status = 'succeeded';
				state.entries = action.payload.data;
				state.total = action.payload.total;
			})
			.addCase(fetchAdminTechnicians.rejected, (state, action) => {
				state.status = 'failed';
				state.error = action.error.message;
			})
			.addCase(fetchBuilderTechnicians.pending, (state, action) => {
				state.status = 'loading';
			})
			.addCase(fetchBuilderTechnicians.fulfilled, (state, action) => {
				state.status = 'succeeded';
				state.entries = action.payload.data;
				state.total = action.payload.total;
			})
			.addCase(fetchBuilderTechnicians.rejected, (state, action) => {
				state.status = 'failed';
				state.error = action.error.message;
			});
	},
});

export const { setEntries, setPerPage, setPage, setTotal, setFilter, setSortByAndSortOrder, setSelectedTechnician, resetTechnicianFilter } =
	TechnicianOverviewSlice.actions;

export default TechnicianOverviewSlice.reducer;

export const getPage = (state) => state.technicianOverview.page;
export const getPerPage = (state) => state.technicianOverview.perPage;
export const getSelectedTechnician = (state) => state.technicianOverview.selectedTechnician;
export const getTotal = (state) => state.technicianOverview.total;
export const getTechnicians = (state) => state.technicianOverview.entries;
export const getStatus = (state) => state.technicianOverview.status;
export const getError = (state) => state.technicianOverview.error;
export const getFilter = (state) => state.technicianOverview.filter;
export const getSortColumn = (state) => state.technicianOverview.sortBy;
export const getSortOrder = (state) => state.technicianOverview.sortOrder;
