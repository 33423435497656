import { createContext, useState } from 'react';

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
	const [auth, setAuth] = useState({});
	const [persist, setPersist] = useState(JSON.parse(localStorage.getItem('persist') || false));
	const accessToken = !auth?.accessToken ? null : auth?.accessToken;
	const split = !!accessToken ? accessToken.split('.')[1] : '';
	const buffer = !!accessToken ? Buffer.from(split, 'base64') : null;
	const parsed = !!accessToken ? JSON.parse(buffer) : null;
	const hasAuth = !!accessToken;
	const isAdmin = !!parsed && parsed.roles === '101';
	const isIBSTechniker = !!parsed && parsed.roles === '201';
	const isTechniker = !!parsed && parsed.roles === '202';
	const isIBSErrichter = !!parsed && parsed.roles === '301';
	const isErrichter = !!parsed && parsed.roles === '302';
	const isAllowedToBookWithoutPosition = !!parsed && (parsed.additional & 1) === 1;
	const isAllowedToTransferMaterial = !!parsed && (parsed.additional & 2) === 2;
	const closedOrdersInDays = !!parsed && parsed.closedOrdersInDays;

	return (
		<AuthContext.Provider
			value={{
				auth,
				setAuth,
				persist,
				setPersist,
				hasAuth,
				isAdmin,
				isTechniker,
				isIBSTechniker,
				isErrichter,
				isIBSErrichter,
				isAllowedToBookWithoutPosition,
				isAllowedToTransferMaterial,
				closedOrdersInDays,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};

export default AuthContext;
